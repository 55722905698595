import { sbEditable, SbEditableBlok } from '@storyblok/storyblok-editable'
import * as React from 'react'
import { HotjarEvent, useTriggerHotjarSurvey } from '../lib/hooks/useTriggerHotjarSurvey';
import { DynamicComponent } from '../components/DynamicComponent'
import { Seo } from '../components/Seo'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ThemeName } from '../lib/constants'
import { useStoryblok } from '../lib/storyblok'
import styled from 'styled-components'
import { Button } from '../components/UI';
import CustomLink from '../components/CustomLink';
import { typoHeadingXl, typoParagraphIntro } from '../lib/styles/Typography';


// styled component that should be the container with with full width and flex center on both axis
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 80px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  padding-left: 20px;
  padding-right: 20px;
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const Title =  styled.h1`
${typoHeadingXl}
text-align: center;

`
const Subtitle = styled.p`
${typoParagraphIntro}
text-align: center;

`

const Image = styled.img`
  width: 123.484px;
  height: 120px;
`

const ErrorPageTemplate = ({ pageContext }: { pageContext: SbEditableBlok }) => {
  let { story, content } = useStoryblok(pageContext)

  useTriggerHotjarSurvey(HotjarEvent.USER_PERSONA_SURVEY)

  const components = content.body?.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  const image = content.image;

  return (
    <DefaultLayout theme={content.color_theme ? (`theme-${content.color_theme}` as ThemeName) : undefined}>
      <Seo
        title={pageContext?.field_pageTitle_string || pageContext?.name}
        description={pageContext?.field_pageDescription_string}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      <Content>
        <ContentContainer>
          <Image src={image.filename as string} alt={pageContext.content.image} />
          <TextsContainer>
            <Title>{content.Title}</Title>
            <Subtitle>{content.Subtitle}</Subtitle>
          </TextsContainer>
          <Button as={CustomLink} to="/" $ignoreTheme>
            {content.buttonLabel}
          </Button>
        </ContentContainer>
      </Content>

      <div {...sbEditable(story)}>{components}</div>
    </DefaultLayout>
  )
}

export default ErrorPageTemplate
